import React, { useState,useEffect,useCallback } from 'react';
import {
  useParams
} from "react-router-dom";
import { chatBot } from "../componets/bot";

const newChat = {
    start: [
      { message: "hello " },
      { message: "im a bot" },
      { message: "hello " },
      { message: "im a bot" },
      {
        message: "question?",
        options: [
          { message: "a", resolve: "pathA" },
          { message: "b", resolve: "pathB" },
          { message: "c", resolve: "pathC" }
        ]
      }
    ],
    pathA: [{ message: "You answered A" }, { message: "That Was Right" }],
    pathB: [{ message: "You answered B" }, { message: "That Was Wrong" }],
    pathC: [{ message: "You answered C" }, { message: "That Was Wrong" }]
  };
  

  const noop = () => {};

 function Chat({ chatArr }) {

    const [messages, setMessage] = useState([]);
    const [options, setOptions] = useState([]);
    const [typing, setTyping] = useState(false);
    const [selected, setSelected] = useState(() => noop);


    useEffect(() => {
        console.log('vado');
        chatBot({
          onMessage: message => {setMessage(messages => [...messages, message])},
          onQuestion: options =>
            new Promise(resolve => {
              setOptions(options);
              setSelected(() => resolve);
            }).finally(() => {
               console.log('pollino');  
              setOptions([]);
              setSelected(() => noop);
            }),
          onTyping: setTyping
        })(chatArr);
      }, [chatArr]);

    return (
        <div>
            <ul>
                {messages.map((message, i) => (
                <li key={i}>{message} dsd</li>
                ))}
            </ul>
            {typing ? "currently typing" : null}
            {options.map(({ message, resolve }, i) => (
                <button key={i} onClick={() => selected(resolve)}>
                {message}
                </button>
            ))}
            </div>
    );
  }


  export default function Home() {
    return (
        <div className="App">
            <Chat chatArr={newChat} />
        </div>
    )}