const getPosition = (structure, id) =>{
  const pos = structure.map(function(e) { return e.id; }).indexOf(id);
  return pos
}


const baseConfig = {
    typingTime: 500,
    replyTime: 1000
  };
  
  const noop = () => {};
  const jitter = number => number + Math.random() * 300;
  const sleep = (time = 1000) =>(
    new Promise(resolve => setTimeout(resolve, jitter(time)))
  )
  
  const simulateReply = (callback, { typingTime, replyTime }) =>
    sleep(typingTime)
      .then(() => callback(true))
      .then(() => sleep(replyTime))
      .then(() => callback(false));
  
  export const chatBot = ({
    onMessage,
    onQuestion,
    onQuestionNew,
    onTyping = noop,
    posizione,
    restart,
    config = {}
  }) => {

    const opts = { ...baseConfig, ...config };

    
    
    const bot = (chatData, path = "start", position = posizione ? getPosition(chatData, posizione) : 0 , positionTesto = 0 , restartx = restart ) => {

      

      
      
      //alert(restartx)
      //console.log('posizioneeee iniziale');
      //console.log(posizione);

      new Promise(resolve => {

       

        const data = chatData;
        console.log('qui arrivooo ma poi boooo');
     
        const chatLeft = true;

        if(position === -1){
          position= 0
        }

        let message  = data[position];
      

      
      
        
        console.log('mess');
        console.log(message);

        
        console.log('posizione testo');
        console.log(positionTesto);
        
        
        if(message && message.askType === 'mc'){
          delete message.defaultNext;
        }
        
        if(message){
          onMessage(message.testi[positionTesto]);

        }
        
        
        /*message.testi.forEach(element => {
           onMessage(element);
        });*/
        
        console.log('mostro la connessione naturale');
        if(message && message.defaultNext){
          let realpos = getPosition(chatData, message.defaultNext)
          if(realpos === -1){
            return simulateReply(onTyping, opts)
              .then(resolve);
          }
        }
        
        
       

        if(message && message.askType !== 'jm' && message.testi.length === positionTesto+1){
          
            console.log('quiii okkk vai');
          
            return simulateReply(onTyping, opts)
               //.then(() => onQuestion(bottoni))
              
               .then(()=> {console.log('mess da vedere')})
               .then(()=> console.log(message))
               .then(()=> onQuestionNew(message))
              .then((e)=>bot(chatData,path,getPosition(chatData, e),0,false))
              .then(resolve);
          }
        /*if (options) {
          return simulateReply(onTyping, opts)
            .then(() => onQuestion(options))
            .then(newPath => bot(chatData, newPath))
            .then(resolve);
        }*/
  
        console.log('coseee');
        console.log(chatLeft);
        return chatLeft
          ? simulateReply(onTyping, opts)
              .then(() => {
                if( message && message.testi.length > 1  && positionTesto < message.testi.length-1){
                  console.log('positionTesto: '+positionTesto);
                  console.log(message.testi.length);
                  //alert(restart)
                  bot(chatData, path, position  ,positionTesto +1  ) 
                }else{
                  bot(chatData, path, getPosition(chatData, message.defaultNext) ,0)

                }
              })
              .then(resolve)
          : resolve();
      });
    }
    return bot;
  };
  