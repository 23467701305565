import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Room from './pages/room'
import Roomv3 from './pages/roomv3'
import RoomTest from './pages/roomtest'
import Test from './pages/test'


export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/v2/:id">
            <RoomTest />
          </Route>
          <Route path="/v1/:id">
            <Room />
          </Route>
          <Route path="/v3/:id">
            <Roomv3 />
          </Route>
          <Route path='/test'>
            <Test />
           </Route> 
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

