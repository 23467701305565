import React, { useState,useEffect,useCallback ,useRef} from 'react';
import {
  useParams
} from "react-router-dom";
import Webcam from "react-webcam";




  export default function Home() {
    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [close, setClose] = React.useState(false);
    
    
    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();
      }, [webcamRef, setCapturing, mediaRecorderRef]);
    
      const handleDataAvailable = React.useCallback(
        ({ data }) => {
          if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
          }
        },
        [setRecordedChunks]
      );

    const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);  


    const showww = React.useCallback(() => {
        console.log(recordedChunks);
    })

    const handleDownload = React.useCallback(() => {
        if (recordedChunks.length) {
          const blob = new Blob(recordedChunks, {
            type: "video/webm"
          });
          
          const url = URL.createObjectURL(blob);
          console.log('vediamo bloddd');
          console.log(url);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = "react-webcam-stream-capture.webm";
          a.click();
          window.URL.revokeObjectURL(url);
          setRecordedChunks([]);
        }
      }, [recordedChunks]);


    useEffect(() => {
       
        

      }, []);

    return (
        <div className="App">
             
            <button onClick={()=>setClose(!close)} >close</button>
            {close ? (
                null
            ) : (
                <>
                <Webcam mirrored audio={true} ref={webcamRef} />
            {capturing ? (
                <button onClick={handleStopCaptureClick}>Stop Capture</button>
            ) : (
                <button onClick={handleStartCaptureClick}>Start Capture</button>
            )}
            {recordedChunks.length > 0 && (
                <button onClick={handleDownload}>Download</button>
            )}
            <button onClick={()=>showww()}>showww</button>
                </>
            )}
            
        </div>
    )}