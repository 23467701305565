const getPosition = (structure, id) =>{
  const pos = structure.map(function(e) { return e.id; }).indexOf(id);
  return pos
}

const salvaPosizione = async (id,dato) =>{
  const axios = require('axios');
  let size = encodeURI(JSON.stringify(dato)).split(/%..|./).length - 1;  
  const kiloBytes = size / 1024;
  const megaBytes = kiloBytes / 1024;
  console.log('la risposta pesa: kb'+kiloBytes+', Mb'+megaBytes);
  let obj = {}
  obj.testDatoSave= JSON.stringify(dato);

  let resppp = await axios.put(
        'https://rivolta-form.herokuapp.com/api/v1/responde/edit/'+id,
        obj
      );


    
  console.log('salvato');    
  return resppp

}


const baseConfig = {
    typingTime: 500,
    replyTime: 1000
  };
  
  const noop = () => {};
  const jitter = number => number + Math.random() * 300;
  const sleep = (time = 1000) =>(
    new Promise(resolve => setTimeout(resolve, jitter(time)))
  )
  
  const simulateReply = (callback, { typingTime, replyTime }) =>
    sleep(typingTime)
      .then(() => callback(true))
      .then(() => sleep(replyTime))
      .then(() => callback(false));
  
  export const chatBot = ({
    onMessage,
    onQuestion,
    onQuestionNew,
    onTyping = noop,
    posizione,
    restart,
    idRisposte,
    config = {}
  }) => {

    const opts = { ...baseConfig, ...config };

    
    
    const bot = (chatData, path = "start", position = posizione ? getPosition(chatData, posizione) : 0 , positionTesto = 0 , restartx = restart ) => {

      
      //alert(restartx)
      //console.log('posizioneeee iniziale');
      //console.log(posizione);

      new Promise(async resolve => {
        const data = chatData;

        
        const chatLeft = true;
        let message  = data[position];

      
      
        
        

        
        
        

        

        onMessage(message.testi[positionTesto]);
        
        
        /*message.testi.forEach(element => {
           onMessage(element);
        });*/
        
        if(message.defaultNext){
          let realpos = getPosition(chatData, message.defaultNext)
          if(realpos === -1){
            return simulateReply(onTyping, opts)
              .then(resolve);
          }
        }
        
        

        if((message.askType !== 'jm' && message.testi.length === positionTesto+1) && message.askType !== 'wbHook'){
         
          
            return simulateReply(onTyping, opts)
               //.then(() => onQuestion(bottoni))

               .then(()=> {console.log('mess da vedere')})
               .then(()=> console.log(message))
               .then(()=> onQuestionNew(message))
              .then((e)=>bot(chatData,path,getPosition(chatData, e),0,false))
              .then(resolve);
          }


          if(message.askType === 'wbHook'){
            
            const axios = require('axios');

            try {
              const resp = await axios.get('https://jsonplaceholder.typicode.com/comments?postId=1');
              console.log(resp.data);
              alert('fatto')

              return simulateReply(onTyping, opts)
              .then(()=> salvaPosizione(idRisposte,resp.data))
              .then(()=> bot(chatData, path, getPosition(chatData, message.defaultNext) ,0))
               
              
            } catch (err) {
              // Handle Error Here
              console.error(err);
              return simulateReply(onTyping, opts)
              .then(()=> alert('errorroneeee'))
              .then(()=> bot(chatData, path, getPosition(chatData, message.defaultNext) ,0))
            }
        

          }



        


        /*if (options) {
          return simulateReply(onTyping, opts)
            .then(() => onQuestion(options))
            .then(newPath => bot(chatData, newPath))
            .then(resolve);
        }*/
  
        
        return chatLeft
          ? simulateReply(onTyping, opts)
              .then(() => {
                if(  message.testi.length > 1  && positionTesto < message.testi.length-1){
                  
                  //alert(restart)
                  bot(chatData, path, position  ,positionTesto +1  ) 
                }else{
                   bot(chatData, path, getPosition(chatData, message.defaultNext) ,0)
                }
              })
              .then(resolve)
          : resolve();
      });
    }
    return bot;
  };
  